import React, { useState } from "react";
import { Button, Text, TextInput, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import "./landingStyles.css";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons from react-icons

import { IconUser, IconAt } from "@tabler/icons-react";
import { NativeSelect, rem } from "@mantine/core";
import { Modal } from "@mantine/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";

const Landing = () => {
  const emailIcon = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const navigate = useNavigate();

  const handleSalesClick = () => {
    navigate("/sales");
  };

  const handleHomeClick = () => {
    navigate("/");
  };
  const handleHealthClick = () => {
    navigate("/health");
  };

  const handleCreatorsClick = () => {
    navigate("/creators");
  };

  //-----------------------------mantine button-----------------------------------------------//

  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // Mobile screens
  const isMediumScreen = useMediaQuery("(max-width: 1024px)"); // Tablet screens

  let buttonSize;
  if (isSmallScreen) {
    buttonSize = "sm";
  } else if (isMediumScreen) {
    buttonSize = "md";
  } else {
    buttonSize = "xl";
  }
  //-----------------------------------------Form data-------------------------------------------//

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const API_URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email address.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        setFormData({ email: "" }); // Reset form data when modal is closed
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("Error occurred during form submission.");
    }
  };

  //---------------------------------------coming soon modal----------------------------------//

  const [opened, { open, close }] = useDisclosure(false);
  const handleClose = () => {
    setFormData({ email: "" }); // Reset form data when modal is closed
    close();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="bg-[#0f0f10] font-excon px-1 md:px-0">
        {/* first section */}
        <div>
          <div className="pb-20 section1-background">
            {/* Navbar */}
            <div className="pt-10">
              <div className="flex items-center justify-between px-4 py-1 mb-20 bg-[#232125] mx-4 md:mx-10 lg:mx-20 xl:mx-40 rounded-3xl border border-[#2e2c33]">
                {/* Logo */}
                <div className="ml-2 sm:ml-4">
                  <img
                    src="images/velara-logo.png"
                    alt="velara-logo"
                    className="w-full h-auto"
                  />
                </div>

                {/* Hamburger Icon for Mobile */}
                <div className="block lg:hidden">
                  <button
                    className={`${
                      isOpen ? "py-0" : "py-2"
                    } text-white focus:outline-none`}
                    onClick={toggleNavbar}
                  >
                    {isOpen ? (
                      <FiX className="w-6 h-6 transition-transform duration-300" />
                    ) : (
                      <FiMenu className="w-6 h-6 transition-transform duration-300" />
                    )}
                  </button>
                </div>

                {/* Mobile Navbar Items */}
                <div
                  className={`${
                    isOpen
                      ? "translate-y-0 opacity-100"
                      : "-translate-y-full opacity-0"
                  } fixed top-0 left-0 right-0 bg-[#232125] z-50 transition-all duration-500 ease-in-out lg:hidden h-auto max-h-[50vh] overflow-hidden rounded-b-3xl`}
                >
                  <div className="flex flex-col items-center justify-center py-6 space-y-6">
                    {/* Close Button */}
                    <div className="flex items-center justify-between w-full px-6">
                      <img
                        src="images/velara-logo.png"
                        alt="velara-logo"
                        className=""
                      />
                      <button className="text-white" onClick={toggleNavbar}>
                        <FiX className="w-8 h-8" />
                      </button>
                    </div>

                    {/* Navbar Items */}
                    <Button
                      variant="transparent"
                      size="xl"
                      color="#a8a8a8"
                      onClick={handleHomeClick}
                    >
                      Home
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleSalesClick}
                      color="#a8a8a8"
                    >
                      Sales Team
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleCreatorsClick}
                      color="#a8a8a8"
                    >
                      Creators
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleHealthClick}
                      color="#a8a8a8"
                    >
                      Health Care
                    </Button>
                  </div>
                </div>

                {/* Centered buttons (visible on large screens) */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:items-center lg:space-x-2">
                  <Button
                    variant="transparent"
                    size="xl"
                    color="#a8a8a8"
                    onClick={handleHomeClick}
                  >
                    Home
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleSalesClick}
                    color="#a8a8a8"
                  >
                    Sales Team
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleCreatorsClick}
                    color="#a8a8a8"
                  >
                    Creators
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleHealthClick}
                    color="#a8a8a8"
                  >
                    Health Care
                  </Button>
                </div>
              </div>
            </div>
            {/* Main section */}
            <div className="flex flex-col items-center space-x-0 text-center lg:space-x-10">
              <div className="mt-10 md:mt-12 lg:mt-16">
                <h1 className="mb-6 text-2xl font-semibold leading-tight text-white md:leading-tight lg:leading-tight sm:text-3xl md:text-4xl lg:text-5xl">
                  AI-Driven Video Solution that <br />
                  <span className="text-[#b28efa]">Personalizes </span> Your
                  Message
                  <span className="text-[#b28efa]"> One-on-One. </span>
                </h1>
                <p className="text-lg sm:text-xl md:text-2xl text-[#b7b5be] mt-4">
                  Bring Your Message to Life: AI-Generated Videos from Text
                </p>

                <div className="flex items-center justify-center mt-8 md:mt-12">
                  <Button
                    variant="filled"
                    size={buttonSize}
                    radius="md"
                    color="#b28efa"
                    onClick={open}
                  >
                    Join the waitlist
                  </Button>
                </div>
              </div>

              <div className="flex justify-center mt-8 md:mt-10">
                <img
                  src="images/landing-section-1.png"
                  alt="velara"
                  className="w-full h-auto "
                />
              </div>
            </div>
          </div>
        </div>

        {/* section two */}
        <div className="px-4 pt-16 pb-20 sm:px-8 md:px-16 lg:px-20 sm:pt-20 md:pt-24 lg:pt-28">
          <div className="flex flex-col items-center justify-center space-y-8 sm:space-y-10 md:space-y-12 lg:space-y-16">
            <div>
              <img
                src="images/landing-features-button.png"
                alt="features"
                className="w-full h-auto"
              />
            </div>

            <h1 className="text-2xl font-semibold leading-tight text-center text-white md:leading-tight lg:leading-tight sm:text-3xl md:text-4xl lg:text-5xl">
              Personalized Outreach with
              <br /> Measurable Success
            </h1>

            <div className="flex justify-center">
              <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center text-[#b5b4b8]">
                Give your team an easy way to progress deals and unlock revenue
                growth.
              </h1>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center py-10 space-y-5 sm:py-12 md:py-16 lg:py-20">
            <div className="flex flex-col space-y-5 sm:flex-row sm:space-y-0 sm:space-x-1">
              <div className="p-8 space-y-8 bg-transparent sm:p-10 sm:space-y-10 rounded-2xl">
                <img alt="engage" src="/images/landing-convert.png" />
                <h1 className="text-2xl font-semibold text-white sm:text-3xl md:text-4xl">
                  Engage
                </h1>
                <ul className="space-y-2">
                  <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                    <span className="mr-2 text-4xl sm:text-5xl text-[#b28efa]">
                      &bull;
                    </span>
                    <div>
                      Automatically personalize every video to
                      <span className="block">
                        your customers and prospects
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                    <span className="mr-2 text-4xl sm:text-5xl text-[#b28efa]">
                      &bull;
                    </span>
                    Record only once and send to many
                  </li>
                </ul>
              </div>

              <div className="p-8 space-y-8 bg-transparent sm:p-10 sm:space-y-10 rounded-2xl">
                <img alt="convert" src="/images/landing-engage.png" />
                <h1 className="text-2xl font-semibold text-white sm:text-3xl md:text-4xl">
                  Convert
                </h1>
                <ul className="space-y-2">
                  <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                    <span className="mr-2 text-4xl sm:text-5xl text-[#b28efa]">
                      &bull;
                    </span>
                    Always give customers a human to chat.
                  </li>
                  <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                    <span className="mr-2 text-4xl sm:text-5xl text-[#b28efa]">
                      &bull;
                    </span>
                    Automate customer support and close leads faster.
                  </li>
                </ul>
              </div>

              <div className="p-8 space-y-8 bg-transparent sm:p-10 sm:space-y-10 rounded-2xl">
                <img alt="retain" src="/images/landing-retain.png" />
                <h1 className="text-2xl font-semibold text-white sm:text-3xl md:text-4xl">
                  Retain Users
                </h1>
                <ul className="space-y-2">
                  <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                    <span className="mr-2 text-4xl sm:text-5xl text-[#b28efa]">
                      &bull;
                    </span>
                    Engage with customers and prospects
                    <br /> like you know them
                  </li>
                  <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                    <span className="mr-2 text-4xl sm:text-5xl text-[#b28efa]">
                      &bull;
                    </span>
                    Humanize communication and don’t be
                    <br /> a random stranger
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* third section */}
        <div className="flex flex-col items-center justify-center pt-10 pb-20 space-y-10 md:flex-row md:pt-16 lg:pt-20 md:pb-28 lg:pb-40 md:space-y-0 md:space-x-10 lg:space-x-20 section3-background">
          <div className="flex flex-col space-y-6 text-left md:space-y-8 lg:space-y-10">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-left leading-tight md:leading-tight lg:leading-tight text-[#b28efa]">
              Personalized communication <br />
              <span className="text-white">from One recording</span>
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-left text-[#b5b4b8]">
              Automatically personalize every video to your <br /> customers and
              prospects
            </h1>
          </div>
          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/landing-section-3.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* fourth section */}
        <div className="py-32 sm:py-40 md:py-48 lg:py-64 section4-background">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl text-center text-white sm:text-3xl md:text-4xl lg:text-5xl ">
              Custom built for
              <span className="text-[#b28efa]"> sales teams </span>
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center text-[#b5b4b8]">
              Who refuse to settle for second place.
            </h1>
          </div>

          <div className="flex items-center justify-center py-10 sm:py-14 md:py-16 lg:py-20">
            <Button
              variant="filled"
              size={buttonSize}
              radius="md"
              color="#b28efa"
              onClick={open}
            >
              Join the waitlist
            </Button>
          </div>
        </div>

        {/* fifth section */}
        <div className="flex flex-col-reverse items-center justify-center py-16 space-y-10 md:flex-row md:space-y-0 md:space-x-10 lg:space-x-40 md:py-20 lg:py-28 section5-background">
          <div className="py-10 md:py-20">
            <img
              src="images/landing-section-5.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
          <div className="text-center md:text-right">
            <h1 className="text-4xl leading-tight text-white md:leading-tight lg:leading-tight sm:text-3xl md:text-4xl lg:text-5xl">
              Best-in-class models <br />
              powering
              <span className="text-[#b28efa]"> revenue growth</span>
            </h1>
          </div>
        </div>

        {/* sixth section */}
        <div className="py-32 sm:py-10 md:py-20 lg:py-40 section6-background">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl leading-tight text-center text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              A Better Way to prospect and <br />
              progress deals in your pipeline
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center text-[#b5b4b8]">
              Ready to generate your first video?
            </h1>
          </div>
          <div className="flex items-center justify-center py-10 sm:py-14 md:py-16 lg:py-20">
            <Button
              variant="filled"
              size={buttonSize}
              radius="md"
              color="#b28efa"
              onClick={open}
            >
              Join the waitlist
            </Button>
          </div>
        </div>

        {/* eigth section */}
        {/* <div className="p-40 section8-background">
          <div className="flex flex-col items-center justify-center mb-20">
            <h1 className="text-5xl font-bold text-white ">
              Contact Us
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center space-y-6">
            <TextInput
              leftSectionPointerEvents="none"
              variant="filled"
              size="xl"
              radius="lg"
              leftSection={emailIcon}
              placeholder="Email"
              className="w-[45rem]"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              type="email"
            />

            <Button
              variant="filled"
              size="lg"
              radius="md"
              style={{ color: "white" }}
              color="#7b5aff"
              className=""
              onClick={handleSubmit}
            >
              Contact Us
            </Button>
          </div>
        </div> */}
      </div>
      <ToastContainer />

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        overlayOpacity={0.5}
        overlayBlur={3}
        padding={"2rem"}
        size="auto"
      >
        <div className="pb-10 space-y-4">
          <h1 className=" text-5xl font-bold text-[#5f3dc4] text-center">
            🎉 Coming Soon!{" "}
          </h1>
          <h1 className="p-6 text-3xl font-semibold text-[#6b7280] text-center">
            Subscribe to get early access and be the
            <br /> first to know when we launch!
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6">
          <TextInput
            leftSectionPointerEvents="none"
            variant="filled"
            size="xl"
            radius="lg"
            leftSection={emailIcon}
            placeholder="Email"
            className="md:w-[35rem]"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            type="email"
          />

          <Button
            variant="filled"
            size="lg"
            radius="md"
            style={{ color: "white" }}
            color="#7b5aff"
            className=""
            onClick={handleSubmit}
          >
            Contact Us
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Landing;

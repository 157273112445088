import React, { useState } from "react";
import { Button, Text, TextInput, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import "./SalesStyles.css";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons from react-icons
import { useMediaQuery } from "@mantine/hooks";

import { IconUser, IconAt } from "@tabler/icons-react";
import { NativeSelect, rem } from "@mantine/core";
import { Modal } from "@mantine/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";

const Sales = () => {
  const emailIcon = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const navigate = useNavigate();

  const handleSalesClick = () => {
    navigate("/sales");
  };
  const handleHomeClick = () => {
    navigate("/");
  };

  const handleCreatorsClick = () => {
    navigate("/creators");
  };
  const handleHealthClick = () => {
    navigate("/health");
  };

  //-----------------------------mantine button-----------------------------------------------//

  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // Mobile screens
  const isMediumScreen = useMediaQuery("(max-width: 1024px)"); // Tablet screens

  let buttonSize;
  if (isSmallScreen) {
    buttonSize = "sm";
  } else if (isMediumScreen) {
    buttonSize = "md";
  } else {
    buttonSize = "xl";
  }

  //-----------------------------------------Form data-------------------------------------------//

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const API_URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email address.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        setFormData({ email: "" }); // Reset form data when modal is closed
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("Error occurred during form submission.");
    }
  };

  //---------------------------------------coming soon modal----------------------------------//

  const [opened, { open, close }] = useDisclosure(false);
  const handleClose = () => {
    setFormData({ email: "" }); // Reset form data when modal is closed
    close();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="bg-[#0f0f10] font-excon px-1 md:px-0">
        {/* first section */}
        <div>
          <div className="pb-20 sales-section1-background">
            {/* Navbar */}
            <div className="pt-10">
              <div className="flex items-center justify-between px-4 py-1 mb-20 bg-[#232125] mx-4 md:mx-10 lg:mx-20 xl:mx-40 rounded-3xl border border-[#2e2c33]">
                {/* Logo */}
                <div className="ml-2 sm:ml-4">
                  <img
                    src="images/velara-logo.png"
                    alt="velara-logo"
                    className="w-full h-auto"
                  />
                </div>

                {/* Hamburger Icon for Mobile */}
                <div className="block lg:hidden">
                  <button
                    className={`${
                      isOpen ? "py-0" : "py-2"
                    } text-white focus:outline-none`}
                    onClick={toggleNavbar}
                  >
                    {isOpen ? (
                      <FiX className="w-6 h-6 transition-transform duration-300" />
                    ) : (
                      <FiMenu className="w-6 h-6 transition-transform duration-300" />
                    )}
                  </button>
                </div>

                {/* Mobile Navbar Items */}
                <div
                  className={`${
                    isOpen
                      ? "translate-y-0 opacity-100"
                      : "-translate-y-full opacity-0"
                  } fixed top-0 left-0 right-0 bg-[#232125] z-50 transition-all duration-500 ease-in-out lg:hidden h-auto max-h-[50vh] overflow-hidden rounded-b-3xl`}
                >
                  <div className="flex flex-col items-center justify-center py-6 space-y-6">
                    {/* Close Button */}
                    <div className="flex items-center justify-between w-full px-6">
                      <img
                        src="images/velara-logo.png"
                        alt="velara-logo"
                        className=""
                      />
                      <button className="text-white" onClick={toggleNavbar}>
                        <FiX className="w-8 h-8" />
                      </button>
                    </div>

                    {/* Navbar Items */}
                    <Button
                      variant="transparent"
                      size="xl"
                      color="#a8a8a8"
                      onClick={handleHomeClick}
                    >
                      Home
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleSalesClick}
                      color="#a8a8a8"
                    >
                      Sales Team
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleCreatorsClick}
                      color="#a8a8a8"
                    >
                      Creators
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleHealthClick}
                      color="#a8a8a8"
                    >
                      Health Care
                    </Button>
                  </div>
                </div>

                {/* Centered buttons (visible on large screens) */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:items-center lg:space-x-2">
                  <Button
                    variant="transparent"
                    size="xl"
                    color="#a8a8a8"
                    onClick={handleHomeClick}
                  >
                    Home
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleSalesClick}
                    color="#a8a8a8"
                  >
                    Sales Team
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleCreatorsClick}
                    color="#a8a8a8"
                  >
                    Creators
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleHealthClick}
                    color="#a8a8a8"
                  >
                    Health Care
                  </Button>
                </div>
              </div>
            </div>
            {/* first section */}
            <div className="flex flex-col items-center justify-center pt-10 pb-20 space-y-10 md:flex-row md:pb-28 lg:pb-32 md:space-y-0 md:space-x-20 lg:space-x-40">
              <div className="flex flex-col items-center justify-center space-y-6 text-left md:items-start md:justify-start sm:space-y-8 md:space-y-10 w-[40vh] md:w-[75vh]">
                <h1 className="text-2xl leading-tight text-center text-white md:text-left sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
                  The{" "}
                  <span className="text-center text-[#b28efa]">
                    Largest Selling
                  </span>{" "}
                  AI Contact Platform For Prospecting and Increasing Your
                  <span className="text-center text-[#b28efa]">
                    {" "}
                    Monthly Sales Target
                  </span>
                </h1>

                <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center md:text-left text-[#b5b4b8]">
                  For sales teams who want to win president's club, book more{" "}
                  meetings, and exceed their targets with the largest-selling AI
                  video platform for sellers.
                </h1>

                <div>
                  <Button
                    variant="filled"
                    size={buttonSize}
                    radius="md"
                    color="#b28efa"
                    onClick={open}
                  >
                    Join the waitlist
                  </Button>
                </div>
              </div>

              <div className="pb-10 sm:pb-14 md:pb-20">
                <img
                  src="images/sales-section-1.png"
                  alt="rocket"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        {/* section two */}
        <div className="flex flex-col justify-center pt-16 pb-20 space-y-10 md:flex-row md:pt-20 lg:pt-28 md:space-y-0 md:space-x-28 lg:space-x-64">
          <div className="flex flex-col items-center justify-center space-y-6 text-left md:items-start md:justify-start sm:space-y-8 md:space-y-10 px-4 md:w-[70vh]">
            <h1 className="text-2xl leading-tight text-center text-white md:text-left sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              The leading AI video generation for sales
            </h1>
            <div>
              <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center md:text-left text-[#b5b4b8]">
                Cut through the noise and start landing deals by personalizing
                outreach using generated videos trained using your voice and
                face.
              </h1>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center space-y-6 md:items-start md:justify-start md:space-y-10">
            <div className="pb-6 space-y-4 bg-transparent md:pb-8 rounded-2xl">
              <img
                alt="team"
                src="/images/sales-team.png"
                className="w-auto h-auto"
              />
              <h1 className="text-2xl font-semibold text-white sm:text-3xl md:text-4xl">
                Team Productivity
              </h1>
              <ul className="space-y-2">
                <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                  Automatically personalize every video to your <br />
                  customers and prospects.
                </li>
              </ul>
            </div>

            <div className="pb-6 space-y-4 bg-transparent md:pb-8 rounded-2xl">
              <img
                alt="revenue"
                src="/images/sales-revenue.png"
                className="w-auto h-auto"
              />
              <h1 className="text-2xl font-semibold text-white sm:text-3xl md:text-4xl">
                Revenue Growth
              </h1>
              <ul className="space-y-2">
                <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                  Always give customers a human to chat.
                </li>
              </ul>
            </div>

            <div className="pb-6 space-y-4 bg-transparent md:pb-8 rounded-2xl">
              <img
                alt="consistent"
                src="/images/sales-consistent.png"
                className="w-auto h-auto"
              />
              <h1 className="text-2xl font-semibold text-white sm:text-3xl md:text-4xl">
                Consistent Deal Flow
              </h1>
              <ul className="space-y-2">
                <li className="flex items-center text-base sm:text-lg md:text-xl text-[#b5b4b8]">
                  Engage with customers and prospects like you
                  <br /> know them.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* third section */}
        <div className="flex flex-col-reverse items-center justify-center py-8 space-y-10 md:flex-row md:py-12 lg:py-20 md:space-y-0 md:space-x-20 lg:space-x-40 ">
          <div className="flex flex-col items-center justify-center space-y-4 text-left md:items-start md:justify-start sm:space-y-6 md:space-y-8 w-[40vh] md:w-[70vh]">
            <h1 className="text-2xl leading-tight text-left text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Record a video of yourself
            </h1>
            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center md:text-left text-[#b5b4b8]">
              or you could upload a video of yourself and generate multiple{" "}
              videos personalized to your prospects.
            </h1>
            <div>
              <Button
                variant="filled"
                size={buttonSize}
                radius="md"
                color="#b28efa"
                onClick={open}
              >
                Join the waitlist
              </Button>
            </div>
          </div>

          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/sales-section-3.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* fourth section */}
        <div className="flex flex-col-reverse items-center justify-center py-8 space-y-10 md:flex-row md:py-12 lg:py-20 sales-section4-background md:space-y-0 ">
          <div className="flex flex-col items-center justify-center space-y-6 text-left md:items-start md:justify-start sm:space-y-8 md:space-y-10 w-[40vh]  md:w-[70vh]">
            <h1 className="text-2xl leading-tight text-center text-white md:text-left sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Flywheel for ambitious
              <span className="text-[#b28efa]"> sales teams</span>
            </h1>
            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center md:text-left text-[#b5b4b8]">
              Velara is built for sales teams who want to consistently exceed
              their target and win President's club.
            </h1>
          </div>

          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/sales-section-4.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
        </div>
        {/* fifth section */}
        <div className="flex flex-col-reverse items-center justify-center py-8 space-y-10 md:flex-row md:py-12 lg:py-20 md:space-y-0 md:space-x-20 lg:space-x-40 sales-section5-background">
          <div className="flex flex-col space-y-6 text-left sm:space-y-8 md:space-y-10 w-[40vh] md:w-[70vh]">
            <h1 className="text-2xl leading-tight text-white md:leading-tight lg:leading-tight sm:text-3xl md:text-4xl lg:text-5xl">
              Experience how <span className="text-[#b28efa]">Velara</span> can
              help you exceed your{" "}
              <span className="text-[#b28efa]">sales target</span>
            </h1>
            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-left text-[#b5b4b8]">
              Drive revenue growth using personalized video.
            </h1>
          </div>

          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/sales-section-5.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* sixth section */}
        <div className="py-32 sm:py-40 md:py-48 lg:py-64 sales-section6-background">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl text-center text-white sm:text-3xl md:text-4xl lg:text-5xl">
              Put yourself in front of your prospects
            </h1>
          </div>
          <div className="flex items-center justify-center py-10 sm:py-14 md:py-16 lg:py-20">
            <Button
              variant="filled"
              size={buttonSize}
              radius="md"
              color="#b28efa"
              onClick={open}
            >
              Join the waitlist
            </Button>
          </div>
        </div>

        {/* section seventh */}
        {/* <div className="px-20 pb-20 pt-28 sales-section7-background">
          <div className="flex flex-col items-center justify-center space-y-16">
            <div>
              {" "}
              <img
                src="images/sales-review-button.png"
                alt="features"
                className="w-full h-auto"
              />
            </div>

            <h1 className="text-6xl font-semibold text-center text-white ">
              What People say about us?
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center py-20 space-y-5">
            <div className="flex flex-row space-x-5">
              <div className="p-10 space-y-10 bg-[#19181a] rounded-2xl ">
                <img alt="engage" src="/images/purple-stars.png" />
                <h1 className="text-2xl  text-[#b5b4b8] ">
                  "Love the simplicity of the service
                  <br /> and the prompt customer support.
                  <br /> We can't imagine working without
                  <br /> it"
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    alt="review-lady"
                    src="/images/review-lady.png"
                    className="w-auto h-full"
                  />
                  <div className="flex flex-col space-y-1">
                    <h1 className="text-[#b5b4b8] text-2xl font-semibold">
                      Mathilde Lewis
                    </h1>
                    <h1 className="text-[#89888d] text-md">
                      Head of Design, Layers
                    </h1>
                  </div>
                </div>
              </div>
              <div className="p-10 space-y-10 bg-[#19181a] rounded-2xl ">
                <img alt="engage" src="/images/yellow-stars.png" />
                <h1 className="text-2xl  text-[#b5b4b8] ">
                  "Love the simplicity of the service
                  <br /> and the prompt customer support.
                  <br /> We can't imagine working without
                  <br /> it"
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    alt="review-lady"
                    src="/images/review-lady.png"
                    className="w-auto h-full"
                  />
                  <div className="flex flex-col space-y-1">
                    <h1 className="text-[#b5b4b8] text-2xl font-semibold">
                      Mathilde Lewis
                    </h1>
                    <h1 className="text-[#89888d] text-md">
                      Head of Design, Layers
                    </h1>
                  </div>
                </div>
              </div>{" "}
              <div className="p-10 space-y-10 bg-[#19181a] rounded-2xl ">
                <img alt="engage" src="/images/orange-stars.png" />
                <h1 className="text-2xl  text-[#b5b4b8] ">
                  "Love the simplicity of the service
                  <br /> and the prompt customer support.
                  <br /> We can't imagine working without
                  <br /> it"
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    alt="review-lady"
                    src="/images/review-lady.png"
                    className="w-auto h-full"
                  />
                  <div className="flex flex-col space-y-1">
                    <h1 className="text-[#b5b4b8] text-2xl font-semibold">
                      Mathilde Lewis
                    </h1>
                    <h1 className="text-[#89888d] text-md">
                      Head of Design, Layers
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-5">
              <div className="p-10 space-y-10 bg-[#19181a] rounded-2xl ">
                <img alt="engage" src="/images/purple-stars.png" />
                <h1 className="text-2xl  text-[#b5b4b8] ">
                  "Love the simplicity of the service
                  <br /> and the prompt customer support.
                  <br /> We can't imagine working without
                  <br /> it"
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    alt="review-lady"
                    src="/images/review-lady.png"
                    className="w-auto h-full"
                  />
                  <div className="flex flex-col space-y-1">
                    <h1 className="text-[#b5b4b8] text-2xl font-semibold">
                      Mathilde Lewis
                    </h1>
                    <h1 className="text-[#89888d] text-md">
                      Head of Design, Layers
                    </h1>
                  </div>
                </div>
              </div>
              <div className="p-10 space-y-10 bg-[#19181a] rounded-2xl ">
                <img alt="engage" src="/images/yellow-stars.png" />
                <h1 className="text-2xl  text-[#b5b4b8] ">
                  "Love the simplicity of the service
                  <br /> and the prompt customer support.
                  <br /> We can't imagine working without
                  <br /> it"
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    alt="review-lady"
                    src="/images/review-lady.png"
                    className="w-auto h-full"
                  />
                  <div className="flex flex-col space-y-1">
                    <h1 className="text-[#b5b4b8] text-2xl font-semibold">
                      Mathilde Lewis
                    </h1>
                    <h1 className="text-[#89888d] text-md">
                      Head of Design, Layers
                    </h1>
                  </div>
                </div>
              </div>{" "}
              <div className="p-10 space-y-10 bg-[#19181a] rounded-2xl ">
                <img alt="engage" src="/images/orange-stars.png" />
                <h1 className="text-2xl  text-[#b5b4b8] ">
                  "Love the simplicity of the service
                  <br /> and the prompt customer support.
                  <br /> We can't imagine working without
                  <br /> it"
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    alt="review-lady"
                    src="/images/review-lady.png"
                    className="w-auto h-full"
                  />
                  <div className="flex flex-col space-y-1">
                    <h1 className="text-[#b5b4b8] text-2xl font-semibold">
                      Mathilde Lewis
                    </h1>
                    <h1 className="text-[#89888d] text-md">
                      Head of Design, Layers
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* eighth section */}
        <div className="py-56 sales-section8-background">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl leading-tight text-center text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Are you good to go and ready to <br />
              <span className="text-[#b28efa]">personalize</span> your
              prospecting?
            </h1>
          </div>
          <div className="flex items-center justify-center py-10 sm:py-14 md:py-16 lg:py-20">
            <Button
              variant="filled"
              size={buttonSize}
              radius="md"
              color="#b28efa"
              onClick={open}
            >
              Join the waitlist
            </Button>
          </div>
        </div>

        {/* ninth section */}
        {/* <div className="p-40 section8-background">
          <div className="flex flex-col items-center justify-center mb-20">
            <h1 className="text-5xl font-bold text-white ">
              Contact Us
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center space-y-6">
            <TextInput
              leftSectionPointerEvents="none"
              variant="filled"
              size="xl"
              radius="lg"
              leftSection={emailIcon}
              placeholder="Email"
              className="w-[45rem]"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              type="email"
            />

            <Button
              variant="filled"
              size="lg"
              radius="md"
              style={{ color: "white" }}
              color="#7b5aff"
              className=""
              onClick={handleSubmit}
            >
              Contact Us
            </Button>
          </div>
        </div> */}
      </div>
      <ToastContainer />
      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        overlayOpacity={0.5}
        overlayBlur={3}
        padding={"2rem"}
        size="auto"
      >
        <div className="pb-10 space-y-4">
          <h1 className=" text-5xl font-bold text-[#5f3dc4] text-center">
            🎉 Coming Soon!{" "}
          </h1>
          <h1 className="p-6 text-3xl font-semibold text-[#6b7280] text-center">
            Subscribe to get early access and be the
            <br /> first to know when we launch!
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6">
          <TextInput
            leftSectionPointerEvents="none"
            variant="filled"
            size="xl"
            radius="lg"
            leftSection={emailIcon}
            placeholder="Email"
            className="w-[35rem]"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            type="email"
          />

          <Button
            variant="filled"
            size="lg"
            radius="md"
            style={{ color: "white" }}
            color="#7b5aff"
            className=""
            onClick={handleSubmit}
          >
            Contact Us
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Sales;

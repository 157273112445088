import React, { useState } from "react";
import { Button, Text, TextInput, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons from react-icons
import "./CreatorsStyles.css";
import { IconUser, IconAt } from "@tabler/icons-react";
import { NativeSelect, rem } from "@mantine/core";
import { Modal } from "@mantine/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";

const Creators = () => {
  const emailIcon = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const navigate = useNavigate();

  const handleSalesClick = () => {
    navigate("/sales");
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleCreatorsClick = () => {
    navigate("/creators");
  };
  const handleHealthClick = () => {
    navigate("/health");
  };
  //-----------------------------------------Form data-------------------------------------------//

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const API_URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email address.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        setFormData({ email: "" }); // Reset form data when modal is closed
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("Error occurred during form submission.");
    }
  };

  //-----------------------------mantine button-----------------------------------------------//

  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // Mobile screens
  const isMediumScreen = useMediaQuery("(max-width: 1024px)"); // Tablet screens

  let buttonSize;
  if (isSmallScreen) {
    buttonSize = "sm";
  } else if (isMediumScreen) {
    buttonSize = "md";
  } else {
    buttonSize = "xl";
  }

  //---------------------------------------coming soon modal----------------------------------//

  const [opened, { open, close }] = useDisclosure(false);
  const handleClose = () => {
    setFormData({ email: "" }); // Reset form data when modal is closed
    close();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="bg-[#0f0f10] font-excon px-1 md:px-0">
        {/* first section */}
        <div>
          <div className="pb-20 section1-background-c">
            {/* Navbar */}
            <div className="pt-10">
              <div className="flex items-center justify-between px-4 py-1 mb-20 bg-[#232125] mx-4 md:mx-10 lg:mx-20 xl:mx-40 rounded-3xl border border-[#2e2c33]">
                {/* Logo */}
                <div className="ml-2 sm:ml-4">
                  <img
                    src="images/velara-logo.png"
                    alt="velara-logo"
                    className="w-full h-auto"
                  />
                </div>

                {/* Hamburger Icon for Mobile */}
                <div className="block lg:hidden">
                  <button
                    className={`${
                      isOpen ? "py-0" : "py-2"
                    } text-white focus:outline-none`}
                    onClick={toggleNavbar}
                  >
                    {isOpen ? (
                      <FiX className="w-6 h-6 transition-transform duration-300" />
                    ) : (
                      <FiMenu className="w-6 h-6 transition-transform duration-300" />
                    )}
                  </button>
                </div>

                {/* Mobile Navbar Items */}
                <div
                  className={`${
                    isOpen
                      ? "translate-y-0 opacity-100"
                      : "-translate-y-full opacity-0"
                  } fixed top-0 left-0 right-0 bg-[#232125] z-50 transition-all duration-500 ease-in-out lg:hidden h-auto max-h-[50vh] overflow-hidden rounded-b-3xl`}
                >
                  <div className="flex flex-col items-center justify-center py-6 space-y-6">
                    {/* Close Button */}
                    <div className="flex items-center justify-between w-full px-6">
                      <img
                        src="images/velara-logo.png"
                        alt="velara-logo"
                        className=""
                      />
                      <button className="text-white" onClick={toggleNavbar}>
                        <FiX className="w-8 h-8" />
                      </button>
                    </div>

                    {/* Navbar Items */}
                    <Button
                      variant="transparent"
                      size="xl"
                      color="#a8a8a8"
                      onClick={handleHomeClick}
                    >
                      Home
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleSalesClick}
                      color="#a8a8a8"
                    >
                      Sales Team
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleCreatorsClick}
                      color="#a8a8a8"
                    >
                      Creators
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleHealthClick}
                      color="#a8a8a8"
                    >
                      Health Care
                    </Button>
                  </div>
                </div>

                {/* Centered buttons (visible on large screens) */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:items-center lg:space-x-2">
                  <Button
                    variant="transparent"
                    size="xl"
                    color="#a8a8a8"
                    onClick={handleHomeClick}
                  >
                    Home
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleSalesClick}
                    color="#a8a8a8"
                  >
                    Sales Team
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleCreatorsClick}
                    color="#a8a8a8"
                  >
                    Creators
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleHealthClick}
                    color="#a8a8a8"
                  >
                    Health Care
                  </Button>
                </div>
              </div>
            </div>
            {/* first section */}
            <div className="flex flex-col-reverse items-center justify-center pt-10 pb-20 space-y-10 md:flex-row md:pb-28 lg:pb-32 md:space-y-0 md:space-x-10 lg:space-x-40">
              <div className="flex flex-col items-center justify-center space-y-6 text-left md:items-start md:justify-start sm:space-y-8 md:space-y-10 w-[40vh] md:w-[80vh]">
                <h1 className="text-2xl leading-tight text-left text-white md:leading-tight lg:leading-tight sm:text-3xl md:text-4xl lg:text-5xl">
                  Stop <span className="text-[#b28efa]">Recording,</span> Start{" "}
                  <br />
                  <span className="text-[#b28efa]">Generating</span>
                </h1>
                <h1 className="max-w-full text-center sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl md:text-left text-[#b5b4b8]">
                  For creators who spend sleepless nights editing videos even
                  for the lowest type of content. We clone your voice and face
                  for any video scenario using AI.
                </h1>
                <div>
                  <Button
                    variant="filled"
                    size={buttonSize}
                    radius="md"
                    color="#b28efa"
                    onClick={open}
                  >
                    Join the waitlist
                  </Button>
                </div>
              </div>
              <div className="pb-10 sm:pb-14 md:pb-20">
                <img
                  src="images/creators-section1-1.png"
                  alt="rocket"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        {/* section two */}
        <div className="px-4 pt-4 pb-16 sm:px-8 md:px-16 lg:px-20 md:pb-20">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl font-semibold leading-tight text-center text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              From Engagement to Insights <br />
              to <span className="text-[#b28efa]">Revenue Generation</span>
            </h1>
            <div className="flex justify-center">
              <h1 className="text-base sm:text-lg md:text-xl lg:text-2xl text-center text-[#b5b4b8] leading-normal  md:leading-normal lg:leading-normal">
                Cut through the noise and start landing deals by personalizing
                outreach
                <br className="hidden sm:block" />
                using generated videos trained using your voice and face.
              </h1>
            </div>

            <div>
              <Button
                variant="filled"
                size={buttonSize}
                radius="md"
                color="#b28efa"
                onClick={open}
              >
                Join the waitlist
              </Button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center py-10 space-y-5 sm:py-14 md:py-16 lg:py-20">
            <div className="flex flex-col space-y-5 sm:flex-row sm:space-x-20 lg:space-x-96 sm:space-y-0">
              <div className="flex justify-center">
                <img
                  alt="engage"
                  src="/images/creators-section2-1.png"
                  className="w-full h-auto"
                />
              </div>
              <div className="flex justify-center">
                <img
                  alt="engage"
                  src="/images/creators-section2-2.png"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        {/* section four */}
        <div className="px-4 pb-16 lg:pt-20 sm:px-8 md:px-16 lg:px-20 md:pb-20 section4-background-c">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl font-semibold leading-tight text-center text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              <span className="text-[#b28efa]">Attention</span> is the new
              currency
            </h1>
            <div className="flex justify-center">
              <h1 className="text-base sm:text-lg md:text-xl lg:text-2xl text-center text-[#b5b4b8] leading-normal  md:leading-normal lg:leading-normal">
                Generate content in less time <br className="hidden sm:block" />
                Drive attention and improve your storytelling using{" "}
                <br className="hidden sm:block" />
                hyper-realistic video output
              </h1>
            </div>

            <div>
              <Button
                variant="filled"
                size={buttonSize}
                radius="md"
                color="#b28efa"
                onClick={open}
              >
                Join the waitlist
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-center py-10 sm:py-14 md:py-16 lg:py-20">
            <img
              alt="engage"
              src="/images/creators-section4-1.png"
              className="w-auto h-auto"
            />
          </div>
        </div>

        {/* fifth section */}
        <div className="flex flex-col-reverse items-center justify-center py-5 space-y-10 md:flex-row md:py-10 lg:py-20 md:space-y-0 md:space-x-10 lg:space-x-40 section5-background-c">
          <div className="flex flex-col space-y-6 text-left sm:space-y-8 md:space-y-10 w-[40vh] md:w-[70vh]">
            <h1 className="text-2xl leading-tight text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Total creative control in an inexpensive timely way
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center md:text-left text-[#b5b4b8]">
              We model your style of creation, movement, expression and generate
              custom video of yourself.
            </h1>
          </div>
          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/creators-section5-1.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* sixth section */}
        <div className="py-40 section6-background-c">
          <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-4xl leading-tight text-center text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Experience the difference
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-center text-[#b5b4b8]">
              Start creating with just one video in less time and let AI edit
              for you
            </h1>
          </div>
          <div className="flex items-center justify-center py-10 sm:py-14 md:py-16 lg:py-20">
            <Button
              variant="filled"
              size={buttonSize}
              radius="md"
              color="#b28efa"
              onClick={open}
            >
              Join the waitlist
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        overlayOpacity={0.5}
        overlayBlur={3}
        padding={"2rem"}
        size="auto"
      >
        <div className="pb-10 space-y-4">
          <h1 className=" text-5xl font-bold text-[#5f3dc4] text-center">
            🎉 Coming Soon!{" "}
          </h1>
          <h1 className="p-6 text-3xl font-semibold text-[#6b7280] text-center">
            Subscribe to get early access and be the
            <br /> first to know when we launch!
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6">
          <TextInput
            leftSectionPointerEvents="none"
            variant="filled"
            size="xl"
            radius="lg"
            leftSection={emailIcon}
            placeholder="Email"
            className="md:w-[35rem]"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            type="email"
          />

          <Button
            variant="filled"
            size="lg"
            radius="md"
            style={{ color: "white" }}
            color="#7b5aff"
            className=""
            onClick={handleSubmit}
          >
            Contact Us
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Creators;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import Sales from "./pages/Sales";
import Creators from "./pages/Creators";
import Health from "./pages/Health";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/creators" element={<Creators />} />
        <Route path="/health" element={<Health />} />
      </Routes>
    </BrowserRouter>
  );
}
//WOw THis WOrks
//WoW thiS WorkS AgainL
//Helloe
//hello
//h23dctfvygbh

export default App;
